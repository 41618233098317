const ICON_NAV =[
	{name:'popular',svg:'icon_dtfl_rm_0--svgSprite:all',textName:"_Popular"},
	{name:'slots',svg:'icon_dtfl_dz_0--svgSprite:all',textName:"_Slots"},
	{name:'piscator',svg:'icon_dtfl_by_0--svgSprite:all',textName:"_Pescaria"},
	{name:'',svg:'icon_dtfl_sw_0--svgSprite:all',textName:"_Demo"},
	{name:'recent',svg:'icon_dtfl_zj_0--svgSprite:all',textName:"_Recente"},
	{name:'favorite',svg:'icon_dtfl_sc_0--svgSprite:all',textName:"_Favoritos"},
]

const ICON_NAV3Dian5 =[
	{name:'popular',svg:'icon_dtfl_rm_0--svgSprite:all',textName:"_Popular"},
	{name:'slots',svg:'icon_dtfl_dz_0--svgSprite:all',textName:"_Slots"},
	{name:'piscator',svg:'icon_dtfl_by_0--svgSprite:all',textName:"_Pescaria"},
	{name:'recent',svg:'icon_dtfl_zj_0--svgSprite:all',textName:"_Recente"},
	{name:'favorite',svg:'icon_dtfl_sc_0--svgSprite:all',textName:"_Favoritos"},
]
const ICON_NAV3Dian5Active =[
    require('./../icons/active/icon_dtfl_rm_1.webp'),
    require('./../icons/active/icon_dtfl_dz_1.webp'),
    require('./../icons/active/icon_dtfl_by_1.webp'),
    require('./../icons/active/icon_dtfl_zj_1.webp'),
    require('./../icons/active/icon_dtfl_sc_1.webp')
]

// 	{name:'_Slots',svg:'icon_dtfl_dz_0--svgSprite:all'},
// 	{name:'_Popular',svg:'icon_dtfl_by_0--svgSprite:all'},
// 	{name:'_Demo',svg:'icon_dtfl_sw_0--svgSprite:all'},
// 	{name:'_Recente',svg:'icon_dtfl_zj_0--svgSprite:all'},
// 	{name:'_Favoritos',svg:'icon_dtfl_sc_0--svgSprite:all'},
const ICON_NAVActive =[
    require('./../icons/active/icon_dtfl_rm_1.webp'),
    require('./../icons/active/icon_dtfl_dz_1.webp'),
    require('./../icons/active/icon_dtfl_by_1.webp'),
    require('./../icons/active/icon_dtfl_qkl_1.webp'),
    require('./../icons/active/icon_dtfl_zj_1.webp'),
    require('./../icons/active/icon_dtfl_sc_1.webp')
]

const ICON_search =[
    require('./../icons/active/icon_dtfl_2ss_1.webp'),
    require('./../icons/active/icon_dtfl_2rm_1.webp'),
    require('./../icons/active/icon_dtfl_2zj_1.webp'),
    require('./../icons/active/icon_dtfl_2sc_1.webp'),
]


export {
    ICON_NAV,
    ICON_NAVActive,
	ICON_search,
	ICON_NAV3Dian5,
	ICON_NAV3Dian5Active
};