<template>
	<div v-if="isNavigation">
		<div class="fSUCsj8Vxa0U3_foZRjt">
			<!-- <div class="DrSAta3RAIhChb6OSZTc">
				<div class="nGxwk_oNvWwlxdYhmaMt">
					<img src="https://cdntoos.vr6pg.com/siteadmin/skin/lobby_asset/1-1-38/common/_sprite/icon_dt_1yeb.avif?manualVersion=1&amp;version=b7419af824"
						class="goDRiiBsuEuXD3W1NphN GgAalyCT_nMrUn3ge8Q8 use-skeleton"
						style="width: 0.57rem; height: 0.5rem; object-fit: contain;">
					<div class="R6JnJ5r1Gimo5SZaqJCu">Juros</div>
				</div>
			</div> -->
			<!-- yuebao -->
			<div class="DrSAta3RAIhChb6OSZTc">
				<div class="nGxwk_oNvWwlxdYhmaMt" @click="$router.push({name:'yuebao'})">
					<img src="https://cdntoos.vr6pg.com/siteadmin/skin/lobby_asset/1-1-38/common/_sprite/icon_dt_1yeb.avif?manualVersion=1&amp;version=b7419af824"
						class="goDRiiBsuEuXD3W1NphN GgAalyCT_nMrUn3ge8Q8 use-skeleton"
						style="width: 0.57rem; height: 0.5rem; object-fit: contain;">
					<div class="R6JnJ5r1Gimo5SZaqJCu">Juros</div>
					<!-- <div class="uh6csli2MHzFz87__Phx tool-tips" hidden="hidden"
						style="--cu-top: -0.2rem; --cu-right: 0rem; --cu-left: 0.6rem;">
						<p class="tool-tips-box sNgyWmhVEZVrbSGKDBra"><span>15%</span></p>
						<p class="tool-tips-tail YiNQnpSJUes7u5sqBZhp"></p>
					</div> -->
				</div>
				<div class="nGxwk_oNvWwlxdYhmaMt" @click="$router.push({name:'notice'})">
					<img src="https://cdntoos.vr6pg.com/siteadmin/skin/lobby_asset/1-1-38/common/_sprite/icon_dt_1kf.avif?manualVersion=1&amp;version=2056fbf6a5"
						class="goDRiiBsuEuXD3W1NphN GgAalyCT_nMrUn3ge8Q8 use-skeleton"
						style="width: 0.57rem; height: 0.5rem; object-fit: contain;">
					<div class="R6JnJ5r1Gimo5SZaqJCu">Suporte</div>
		
				</div>
				
				<div class="nGxwk_oNvWwlxdYhmaMt" @click="openUrl(userInfo.set_withdraw_password?'withdraw':'security')">
					<img src="https://cdntoos.vr6pg.com/siteadmin/skin/lobby_asset/1-1-38/common/_sprite/icon_dt_1tx.avif?manualVersion=1&amp;version=02d8c3cb10"
						class="goDRiiBsuEuXD3W1NphN GgAalyCT_nMrUn3ge8Q8 use-skeleton"
						style="width: 0.57rem; height: 0.5rem; object-fit: contain;">
					<div class="R6JnJ5r1Gimo5SZaqJCu">Saques</div>
		
				</div>
				<div class="nGxwk_oNvWwlxdYhmaMt" @click="$store.commit('isDeposit',true)">
					<img src="https://cdntoos.vr6pg.com/siteadmin/skin/lobby_asset/1-1-38/common/_sprite/icon_dt_1cz.avif?manualVersion=1&amp;version=262ea512b8"
						class="goDRiiBsuEuXD3W1NphN GgAalyCT_nMrUn3ge8Q8 use-skeleton"
						style="width: 0.57rem; height: 0.5rem; object-fit: contain;">
					<div class="R6JnJ5r1Gimo5SZaqJCu">Depósito</div>
					<!-- <div class="uh6csli2MHzFz87__Phx tool-tips"
						style="--cu-top: -0.1rem; --cu-right: 0rem; --cu-left: 0.52rem;">
						<p class="tool-tips-box sNgyWmhVEZVrbSGKDBra"><span class="text">+10%</span></p>
						<p class="tool-tips-tail YiNQnpSJUes7u5sqBZhp"></p>
					</div> -->
		
				</div>
				<div class="nGxwk_oNvWwlxdYhmaMt" @click="$router.push({name:'event'})">
					<img src="https://cdntoos.vr6pg.com/siteadmin/skin/lobby_asset/1-1-38/common/_sprite/icon_dt_1yh.avif?manualVersion=1&amp;version=2626fac88e"
						class="goDRiiBsuEuXD3W1NphN GgAalyCT_nMrUn3ge8Q8 use-skeleton"
						style="width: 0.57rem; height: 0.5rem; object-fit: contain;">
					<div class="R6JnJ5r1Gimo5SZaqJCu">Eventos</div>
		
				</div>
				<div class="nGxwk_oNvWwlxdYhmaMt" @click="$router.push({name:'canReceive'})">
					<img src="https://cdntoos.vr6pg.com/siteadmin/skin/lobby_asset/1-1-38/common/_sprite/icon_dt_1fs.avif?manualVersion=1&amp;version=70c5054d0a"
						class="goDRiiBsuEuXD3W1NphN GgAalyCT_nMrUn3ge8Q8 use-skeleton"
						style="width: 0.57rem; height: 0.5rem; object-fit: contain;">
					<div class="R6JnJ5r1Gimo5SZaqJCu">Rebate</div>
				</div>
				<!--  -->
				<div class="nGxwk_oNvWwlxdYhmaMt"  @click="$router.push({name:'notice'})">
					<img src="https://cdntoos.vr6pg.com/siteadmin/skin/lobby_asset/1-1-38/common/_sprite/icon_dt_1yjfq.avif?manualVersion=1&amp;version=629a90114a"
						class="goDRiiBsuEuXD3W1NphN GgAalyCT_nMrUn3ge8Q8 use-skeleton"
						style="width: 0.57rem; height: 0.5rem; object-fit: contain;">
					<div class="R6JnJ5r1Gimo5SZaqJCu">Bônus de Sugestão</div>
				</div>
				<div class="nGxwk_oNvWwlxdYhmaMt" @click="$router.push({name:'setting'})">
					<img src="https://cdntoos.vr6pg.com/siteadmin/skin/lobby_asset/1-1-38/common/_sprite/icon_dt_1aqzx.avif?manualVersion=1&amp;version=41c9059ed5"
						class="goDRiiBsuEuXD3W1NphN GgAalyCT_nMrUn3ge8Q8 use-skeleton"
						style="width: 0.57rem; height: 0.5rem; object-fit: contain;">
					<div class="R6JnJ5r1Gimo5SZaqJCu">Segurança</div>
		
				</div>
				<div class="nGxwk_oNvWwlxdYhmaMt" @click="$router.push({name:'securityConter'})">
					<img src="https://cdntoos.vr6pg.com/siteadmin/skin/lobby_asset/1-1-38/common/_sprite/icon_dt_1sz.avif?manualVersion=1&amp;version=38fc615798"
						class="goDRiiBsuEuXD3W1NphN GgAalyCT_nMrUn3ge8Q8 use-skeleton"
						style="width: 0.57rem; height: 0.5rem; object-fit: contain;">
					<div class="R6JnJ5r1Gimo5SZaqJCu">Dados</div>
				</div>
				<div class="nGxwk_oNvWwlxdYhmaMt" @click="$router.push({name:'report',query:{
					current:0,
				}})">
					<img src="https://cdntoos.vr6pg.com/siteadmin/skin/lobby_asset/1-1-38/common/_sprite/icon_dt_1zhmx.avif?manualVersion=1&amp;version=ad4c4fbf6a"
						class="goDRiiBsuEuXD3W1NphN GgAalyCT_nMrUn3ge8Q8 use-skeleton"
						style="width: 0.57rem; height: 0.5rem; object-fit: contain;">
					<div class="R6JnJ5r1Gimo5SZaqJCu">Conta</div>
		
				</div>
				<div class="nGxwk_oNvWwlxdYhmaMt" @click="$router.push({name:'report',query:{
					current:1,
				}})">
					<img src="https://cdntoos.vr6pg.com/siteadmin/skin/lobby_asset/1-1-38/common/_sprite/icon_dt_1tzjl.avif?manualVersion=1&amp;version=d062262195"
						class="goDRiiBsuEuXD3W1NphN GgAalyCT_nMrUn3ge8Q8 use-skeleton"
						style="width: 0.57rem; height: 0.5rem; object-fit: contain;">
					<div class="R6JnJ5r1Gimo5SZaqJCu">Apostas</div>
		
				</div>
				<div class="nGxwk_oNvWwlxdYhmaMt"@click="$router.push({name:'report',query:{
					current:2,
				}})">
					<img src="https://cdntoos.vr6pg.com/siteadmin/skin/lobby_asset/1-1-38/common/_sprite/icon_dt_1grbb.avif?manualVersion=1&amp;version=bb46d4232f"
						class="goDRiiBsuEuXD3W1NphN GgAalyCT_nMrUn3ge8Q8 use-skeleton"
						style="width: 0.57rem; height: 0.5rem; object-fit: contain;">
					<div class="R6JnJ5r1Gimo5SZaqJCu">Relatório</div>
		
				</div>
				<div class="nGxwk_oNvWwlxdYhmaMt" @click="$router.push({name:'canReceive'})">
					<img src="https://cdntoos.vr6pg.com/siteadmin/skin/lobby_asset/1-1-38/common/_sprite/icon_dt_1jl.avif?manualVersion=1&amp;version=aaca3567ad"
						class="goDRiiBsuEuXD3W1NphN GgAalyCT_nMrUn3ge8Q8 use-skeleton"
						style="width: 0.57rem; height: 0.5rem; object-fit: contain;">
					<div class="R6JnJ5r1Gimo5SZaqJCu">Pendente</div>
				</div>
				<div class="nGxwk_oNvWwlxdYhmaMt" @click="openUrl(userInfo.set_withdraw_password?'withdraw':'security')">
					<img src="https://cdntoos.vr6pg.com/siteadmin/skin/lobby_asset/1-1-38/common/_sprite/icon_dt_1txgl.avif?manualVersion=1&amp;version=fc454bfab9"
						class="goDRiiBsuEuXD3W1NphN GgAalyCT_nMrUn3ge8Q8 use-skeleton"
						style="width: 0.57rem; height: 0.5rem; object-fit: contain;">
					<div class="R6JnJ5r1Gimo5SZaqJCu">Gestão Retiradas</div>
		
				</div>
				<div class="nGxwk_oNvWwlxdYhmaMt" @click="isShow=true">
					<img src="https://cdntoos.vr6pg.com/siteadmin/skin/lobby_asset/1-1-38/common/_sprite/icon_dt_1yuyuan.avif?manualVersion=1&amp;version=af139855a7"
						class="goDRiiBsuEuXD3W1NphN GgAalyCT_nMrUn3ge8Q8 use-skeleton"
						style="width: 0.57rem; height: 0.5rem; object-fit: contain;">
					<div class="R6JnJ5r1Gimo5SZaqJCu">Idioma</div>
				</div>
				<div class="nGxwk_oNvWwlxdYhmaMt" @click="openUrl(userInfo.set_withdraw_password?'withdraw':'security')">
					<img src="https://cdntoos.vr6pg.com/siteadmin/skin/lobby_asset/1-1-38/common/_sprite/icon_dt_1jh.avif?manualVersion=1&amp;version=177265697c"
						class="goDRiiBsuEuXD3W1NphN GgAalyCT_nMrUn3ge8Q8 use-skeleton"
						style="width: 0.57rem; height: 0.5rem; object-fit: contain;">
					<div class="R6JnJ5r1Gimo5SZaqJCu">Tarefas de Apostas</div>
		
				</div>
				<div class="nGxwk_oNvWwlxdYhmaMt" @click="$router.push({name:'rechargeFund'})" >
					<img src="https://cdntoos.vr6pg.com/siteadmin/skin/lobby_asset/1-1-38/common/_sprite/icon_dt_1gjj.avif?manualVersion=1&amp;version=7e488aaff2"
						class="goDRiiBsuEuXD3W1NphN GgAalyCT_nMrUn3ge8Q8 use-skeleton"
						style="width: 0.57rem; height: 0.5rem; object-fit: contain;">
					<div class="R6JnJ5r1Gimo5SZaqJCu">Fundo de Previdência</div>
				</div>
				<div class="nGxwk_oNvWwlxdYhmaMt" @click="$router.push({name:'records'})">
					<img src="https://cdntoos.vr6pg.com/siteadmin/skin/lobby_asset/1-1-38/common/_sprite/icon_dt_1lqjl.avif?manualVersion=1&amp;version=e0f0cce712"
						class="goDRiiBsuEuXD3W1NphN GgAalyCT_nMrUn3ge8Q8 use-skeleton"
						style="width: 0.57rem; height: 0.5rem; object-fit: contain;">
					<div class="R6JnJ5r1Gimo5SZaqJCu">Histórico</div>
				</div>
				<!-- instagram -->
				<div class="nGxwk_oNvWwlxdYhmaMt" @click="openUrlCsusy(config.instagram)">
					<img src="https://cdntoos.vr6pg.com/common/upload/1799509986738814978.png" alt="."
						class="goDRiiBsuEuXD3W1NphN GgAalyCT_nMrUn3ge8Q8 use-skeleton"
						style="width: 0.57rem; height: 0.5rem; object-fit: contain;">
					<div class="R6JnJ5r1Gimo5SZaqJCu">Instagram</div>
				</div>
				<!-- whatsapp -->
				<div class="nGxwk_oNvWwlxdYhmaMt" @click="openUrlCsusy(config.whatsapp)">
					<img src="https://cdntoos.vr6pg.com/common/upload/1828853325912748034.png" alt="."
						class="goDRiiBsuEuXD3W1NphN GgAalyCT_nMrUn3ge8Q8 use-skeleton"
						style="width: 0.57rem; height: 0.5rem; object-fit: contain;">
					<div class="R6JnJ5r1Gimo5SZaqJCu">WhatsApp</div>
				</div>
			</div>
		</div>
		<APopup :isShow="isShow" @_remove="_remove"></APopup>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	
	import APopup from "@/components/Idioma-popup/index.vue";
	export default {
		name: 'records',
		props:{
			isNavigation:{
				type:Boolean,
				default:()=>{
					return false;
				}
			},
			title:{
				type:String,
				default:()=>{
					return '';
				}
			},
			content:{
				type:String,
				default:()=>{
					return '';
				}
			}
		},
		components:{
			APopup
		},
		computed:{
			...mapState(['popList','userInfo','moneyInfo','config'])
		},
		data() {
			return{
				isShow:false,
			}
		},
		methods:{
			_remove(){//
				this.isShow=false
			},
			openUrlCsusy(e){
				if(e!='#'){
					window.open(e, '_blank');
				}
			}
		}
	}

</script>

<style scoped>
	.fSUCsj8Vxa0U3_foZRjt {
		background-color: var(--theme-main-bg-color);
		border: .01rem solid var(--theme-color-line);
		border-radius: .14rem;
		box-sizing: border-box;
		-webkit-box-shadow: 0 .04rem .12rem 0 rgba(0, 0, 0, .1);
		box-shadow: 0 .04rem .12rem 0 rgba(0, 0, 0, .1);
		overflow: auto;
		position: absolute;
		right: 0;
		top: 1.05rem;
		z-index: 100;
		padding: .2rem .1rem .1rem;
		width: 5.4rem
	}

	.DrSAta3RAIhChb6OSZTc {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.nGxwk_oNvWwlxdYhmaMt {
		margin-bottom: .02rem;
		-ms-flex-align: center;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		-ms-flex: 1;
		flex: 1;
		-ms-flex-direction: column;
		flex-direction: column;
		margin-right: .05rem;
		max-width: 1rem;
		min-width: .9rem;
		position: relative
	}

	.R6JnJ5r1Gimo5SZaqJCu {
		-webkit-line-clamp: 4;
		-webkit-box-orient: vertical;
		display: -webkit-box;
		line-height: 1.2;
		min-height: .6rem;
		overflow: hidden;
		text-overflow: ellipsis;
		vertical-align: middle;
		color: var(--theme-text-color);
		font-size: .2rem;
		word-break: break-all;
		text-align: center;
	}
</style>