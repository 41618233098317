<template>
	<div class="login-body">
		<changePassword :changePassword="changePassword"></changePassword>
		<!-- $store.state.overlayShow -->
		<van-popup v-model="$store.state.isRecord" z-index="999999999" :close-on-click-overlay="false">
			<div class="login-register-body">
				<van-tabs v-model="$store.state.isRecordType"
				@click="routerFun" 
				class="h"
				:lineWidth="(activeLength*10+'px')"
				animated
				line-height='.03rem'
				sticky
				offset-top=".9rem"
				background="var(--theme-main-bg-color)" 
				:ellipsis="false"
				title-active-color="var(--theme-primary-color)"
				title-inactive-color="var(--theme-text-color-darken)"
				color="var(--theme-primary-color)">
					<van-tab  title-style="font-size: 0.26rem;" v-for="(item,index) in tabList" :title="$t(item.name)" :name='index' :key="index" >
						<template #title>
							<!-- color: var(--theme-text-color-lighten); -->
							 <svg-icon className="imageWrap" style="width: .26rem;height: .26rem;"  :icon-class="item.icon"  /> {{$t(item.name)}}
						</template>
						<component :is="item.content" @onLogin="onLogin" @changePasswordFun="$store.commit('isRecord'),changePassword=true"></component>
					</van-tab>
				</van-tabs>
			</div>
			<div class="QzRd3YqTnYxG8Latq8fW" @click="$store.commit('isRecord')">
				<img class="closeIcon" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFgAAABYCAMAAABGS8AGAAAAilBMVEUAAAD////e5Orv8vXe5Or////e5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Or////////h5+z8/f7z9ffm6/Dp7fHt8PT5+vv2+PrCEzcDAAAAJXRSTlMAYJYggoAJR6DVzbyRfHYuimpXKBlePTURrlDbxOm27OJwZaif1OMsUAAABGNJREFUWMPdmdtiojAQhlNAOSOoeK6tugRy4P1fb0lCO26DDRiv9r8Twsfkz0wSA/ovtVh6tzj8fP/4eP+M4iBdrpC93GtwJJQ1La+leNswisOgXNtQd9czpg2vNPGG4v1y/iR2lWDaVo9Ud2xv/YyxDmnqnsG77hOCOxFhC/+6znDuTsSuHdx89ZrgU5CW/nbluqutX6ZBJP1RYeN8kiEZZv2DJMr9nfbaZRL2/alZdJ3ignyIU5xvHzXaOJipVsQZaXWBG4UNi99zJuvR7LQcw00Il63xxdw2VTG02DO3dahqmuxG1Y8jw6iJY2g4V1wWH9BIFZEMmjoGLlPv302oI4eayYqLczRJKomoY/CX4wxNVI4F+bHPDpHcAk2Wp8gPcuOChQ/AnURWzx4GByEUiYNT9JQS0ds2HjSiEb1JnoDC+LABmzMCN55SIALDpVb4wn4eWSw4G0k4aR4x8b4rslAq+ky9HyOHlRFWujUiM34ELK9JI2zM0EJewyUbJVrIHuuufNpvQsT40QKB5IVsoOVsNnMRGnEDQuYh/F5S0QV9uX2rhN7QmBuQAwTWyX33osbRw6qU3nSukh5z3Hak/J/iIBut1awC8hC3mmmPlLQfLHDiiAbBQNa51R99CcL1nReOcCIZBgNZ50LEoHPHYl9pIOZLekCDHgNZ54LHoIx1QZ7758VQalUHECBrl3RtCSTYgYh5DSET2cgFkxcQfYDMZANX6dR2vi6/x455aAR5BBcFMHpiIGmJRpDNXDXt9MUW8c4VHxnII7mooN9pEXJZdwaymQvV1qoF6hPG0UQ2c5EPOfbRgfEKmcnANYHDEWAgA9cMBivswboVRzF4W3srYPD4SUs3+8G7iqw4qwJpoUDs0y2VEwQUYfqSAgEavONVJR3L/oPd0asmIVib5Jpd491rps3F3USPjjWkhe1EX1C5sQe/m/w1S9PtfnIvhcmh9WIKFm9grwnzm93yf5AWaxsj6w0LbNagDOt3yy0W9H3z75pNC+tNob7PduS+1mYbCwGy7D6tZRcuA/vrP7C/Nt/IGVTHXfrxI7LTSgacD/zhSezADvz1+nnRt+EWIjj2M7iF6EZztuC6IRd2zjXjqTx+sTCCQQJoRVOR9GkuqWTR6fJxLWwun+OmwmAeDuamR8S96PDUwMlDIVz8ckjSxk+Qr5hrxzOa/+1pshsXzH8feddpBBlfph67cdNpx0KSOZ5SgnOH1JI7R0ZyTZ3FWK6/Z5Xk7kzntrJdg7OxNvSHsfORPavpmOwoI1rD8bFJHm7l2TTZG+akZUz4pO4dYlbLB0hUrB96loVEBlDTYDN6oBPlXMUp3hergTG+xJjxSoXrTTrpupH2+8tPeEuXW3c37964c7eltz8SJu7KTpnzRzewqZTkFyHcS34TqnqsNgwjM5So7irVUvCbMwzmTtTCw6LTuuqWkShzkYV874T774TwpZDgON0ia7mHNDgdu++anT7ej3GQ+Wv0H+ov9UMLyPmx8boAAAAASUVORK5CYII=" alt="">
			</div>
			
			
		</van-popup>
		
	</div>
</template>

<script>
	import {login} from "@/apis/modules/login.js";
	import register from "./register.vue"
	import changePassword from "./changePassword.vue"
	import loIn from "./loIn.vue"
	import { Toast } from 'vant';
	export default {
		components: {
			changePassword
		},
		props:{
			isRecord:{
				type:Boolean,
				default:true,
			}
		},
		data() {
			return{
				iscaptcha_configId:false,
				changePassword:false,
				activeName:1,
				activeLength:12,
				loain:{
					_Inscreverse:'Inscrever-se',
					_AcessoDeMembro:'Acesso de membro',
				},
				captcha:()=>{},
				conTnetfrom:{},
				rememberPassword:[],
				//_Inscreverse 
				tabList:[
					{name:'loain._Inscreverse',
					 icon:'login_icon_zj--svgSprite:all',
					 content:register,
					},
					{name:'loain._AcessoDeMembro',
					icon:'login_icon_dl--svgSprite:all',
					 content:loIn,
					}
				]
			}
		},
		created() {
			let _this=this;
			if(_this.$store.state.config.captcha_config.id){
				this.$initGeetest4({
					captchaId:this.$store.state.config.captcha_config.id,
					product: 'bind',
					language:_this.$store.state.config.captcha_config.language
				}, function (captcha) {
					_this.captcha=captcha;
					captcha.onReady(function () {}).onSuccess(function () {
						var result = captcha.getValidate();
						result.captcha_id =_this.$store.state.config.captcha_config.id;
						_this.checkComplete(_this.conTnetfrom)
					}).onError(function () {
				
					})
					// let co=captcha.showCaptcha();
				});	
			}else{
				this.iscaptcha_configId=true;
			}
			
		},
		methods:{
			routerFun(e,l){
			  this.activeLength=l.length;
			},
			checkComplete(from={},rememberPassword){
				login(from).then(({code,data,msg})=>{
					if(code==0){
						this.$vuex('vuex_token_head',data)
						this.$vuex('vuex_token',data.token)
						this.$store.commit('isRecord')
						window.localStorage.setItem('rememberPassword',JSON.stringify(this.rememberPassword))
						// _this.$router.push({name:'home'});
						location.reload();
					}else{
						Toast.loading({
							message:this.myLineBreaks(msg),
							duration:1000
						});
						// Toast.loading({
							
							// setTimeout(()=>{
							// 	Toast.clear()
							// },1000)
							return false;
						// })
					}
				})
			},
			onLogin(from={},rememberPassword){
				if(from.account){
					if(/^\d+$/.test(from.account) && from.account.length>=10){
						from.type=1;
					}else{
						from.type=2;
					}
				}
				if(rememberPassword){
					rememberPassword.unshift(from)
					this.rememberPassword=rememberPassword;
				}
				this.conTnetfrom=from;
				if(from.isZhuzhe){
					this.rememberPassword=rememberPassword;
					this.checkComplete(from,rememberPassword);
					return false;
				}
				if(!this.iscaptcha_configId){
					this.captcha.showCaptcha();
				}else{
					this.checkComplete(this.conTnetfrom)
				}
			},
		}
	}
</script>
<style>
	.login-body .van-field__body input::-webkit-inner-spin-button {
            -webkit-appearance: none;
    }
	.login-body{
		font-weight: 400;
	}
	.login-body .van-popup{
		background-color: transparent; 
	}
	.login-body .van-tab--active .imageWrap{
		color: var(--theme-primary-color) !important;
	}
	.login-register-body .ant-select-dropdow {
		background-color: var(--theme-main-bg-color);
		border: .01rem solid var(--theme-color-line) !important;
		border-radius: .1rem;
		-webkit-box-shadow: 0 .03rem .09rem 0 rgba(0, 0, 0, .1);
		box-shadow: 0 .03rem .09rem 0 rgba(0, 0, 0, .1);
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		position: absolute;
		z-index: 999;
		max-height: 2.75rem !important;
		overflow: hidden;
		border-radius: .14rem;
		width: 5rem;
		margin-top: .01rem;
		padding: .14rem 0;
	}
	
	.login-register-body .Conter_arrConte {
		display: flex;
		align-items: center;
	
		color: var(--theme-text-color-lighten);
		justify-content: space-between;
		font-weight: 400;
		font-size: .24rem;
		font-size: 0.24rem;
		line-height: 0.8rem;
		min-height: 0.8rem;
		padding: 0px 0.2rem;
	}
	
	.login-register-body .Conter_arrConte.active {
		color: var(--theme-primary-color);
	}
	
	/* color: var(--theme-primary-color); */
	.login-register-body .Conter_arrConte .tengAr {
		color: var(--theme-secondary-color-error);
		font-size: .24rem;
	}
	.login-register-body .van-field__body{
		    font-size: 0.22rem;
			margin-left: 0.1rem;
	}
	.login-register-body  ul li {
	  cursor: pointer;
	}
	html .login-register-body .van-tab__text{
		font-size: .26rem !important;
	}
	html .login-register-body  .van-field__right-icon>div{
		display: flex;
		align-items: center;
	}
</style>
<style scoped>
	.login-register-body{
		background-color: var(--theme-main-bg-color);
		border: thin solid;
		border-color: var(--theme-color-line);
		border-radius: .2rem;
		max-height: calc(100vh - .1rem);
		max-height: calc(var(--vh, 1vh)* 100 - .1rem);
		overflow: auto;
		    width: 6.9rem;
		position: static;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-direction: column;
		flex-direction: column;
		max-height: calc(100vh - 2.4rem);
		padding: .2rem 0;
	}
	.login-body .imageWrap{
		color: var(--theme-text-color-lighten) ;
	}
	.QzRd3YqTnYxG8Latq8fW {
    -ms-flex-align: center;
    -ms-flex-pack: center;
    align-items: center;
    background: none;
    border-radius: 50%;
    bottom: -.88rem;
    display: -ms-flexbox;
    display: flex;
    height: .64rem;
    justify-content: center;
    margin-right: -.3rem;
    position: absolute;
    right: 50%;
    top: auto;
	  width: .64rem
}
.QzRd3YqTnYxG8Latq8fW .closeIcon{
	width: 100%;
	height: 100%;
}
</style>